<template>
  <div class="login-content" v-if="isWechat">
    <div class="login">
      <div style="display: flex; justify-content: center;">
        <img src="../assets/logo.png" alt="打造世界领先的保险风控民族品牌">
      </div>
      <div style="display: flex; justify-content: center; font-weight: bold; font-size: 16px; margin-top: 20px; color: rgb(121 120 120); margin-bottom: 30px;">
        {{ appInfo.name }}登录
      </div>
<!--      <div v-if="isTenant">-->
<!--        <van-field label="企业域"-->
<!--                   placeholder="请输入企业域"-->
<!--                   size="large"-->
<!--                   :center="true"-->
<!--                   v-model="phoneLogin.tenant"-->
<!--                   v-on:keyup.enter="loginByPhone" ref="tenantInput"></van-field>-->
<!--      </div>-->
      <div>
        <van-field type="tel"
                   label="手机号"
                   placeholder="请输入手机号"
                   size="large"
                   :center="true"
                   v-model="phoneLogin.phone" v-on:keyup.enter="loginByPhone" maxlength="11" ref="phoneInput"></van-field>
      </div>
      <div>
        <van-field type="number"
                   label="验证码"
                   placeholder="请输入验证码"
                   size="large"
                   :center="true"
                   v-model="phoneLogin.code" v-on:keyup.enter="loginByPhone" maxlength="4" ref="codeInput">
          <template #button>
            <van-button size="small"
                        type="primary"
                        :round="true"
                        :disabled="timer > 0 && timer !== 60"
                        @click="send">
              {{ (timer === 0 || timer === 60) ? '获取验证码' : '重新发送(' + timer + ')' }}
            </van-button>
          </template>
        </van-field>
      </div>
<!--      <div style="display: flex; margin-top: 15px;" v-if="isCaptcha">-->
<!--        <a-input v-model="phoneLogin.captcha" placeholder="请输入验证码" size="large" style="width: 200px; margin-right: 20px;" v-on:keyup.enter="loginByPhone">-->
<!--          <a-icon slot="prefix" type="picture"></a-icon>-->
<!--        </a-input>-->
<!--        <div>-->
<!--          <img :src="captchaUrl" style="cursor: pointer;" @click="refresh"/>-->
<!--        </div>-->
<!--      </div>-->
      <div style="display: flex; justify-content: center; margin-top: 30px;">
        <van-button size="large"
                    type="primary"
                    style="width: 80%;"
                    :round="true"
                    @click="loginByPhone">登录</van-button>
      </div>
    </div>
  </div>

  <div style="display: flex; justify-content: center; margin-top: 150px; font-size: 18px;" v-else>
    请在微信客户端打开此页面
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as constant from '../public/constant';
import wechat from '../public/wechat';

export default {
  data() {
    return {
      isWechat: wechat.isWeChat()
    };
  },

  computed: {
    ...mapState({
      errorMsg: state => state.login.data.errorMsg,
      error: state => state.login.data.error,
      loading: state => state.login.data.loading,
      phoneLogin: state => state.login.phoneLogin,
      appInfo: state => state.login.data.appInfo,
      isTenant: state => state.login.data.isTenant,
      isFast: state => state.login.data.isFast,
      isCaptcha: state => state.login.data.isCaptcha,
      captchaUrl: state => state.login.data.captchaUrl,
      timer: state => state.login.data.timer,
    }),
    isRememberMe: {
      get() {
        return this.$store.state.login.data.isRememberMe;
      },

      set(val) {
        this.$store.commit('rememberMe', val);
      }
    }
  },

  created() {
    if (!this.isWechat) {
      return;
    }
    this.$store.dispatch('init', this).then((success) => {
      if (success) {
        this.$store.dispatch('copyRememberMePhone');
        this.$store.dispatch('getAppInfo', this).then(() => {
          this.$store.dispatch('triggerCaptcha').then((res) => {
            if (res) {
              this.$store.dispatch('getCaptcha');
            }
          });
        });
      }
    });
  },

  methods: {
    send() {
      this.$store.dispatch('send', this).then(() => {
        this.$store.dispatch('triggerCaptcha').then((res) => {
          if (res) {
            this.$store.dispatch('getCaptcha');
          }
        });
      });
    },

    loginByPhone() {
      this.$store.dispatch('loginByPhone', this).then((type) => {
        if (type === constant.CAPTCHA) {
          this.$store.dispatch('triggerCaptcha').then((res) => {
            if (res) {
              this.$store.dispatch('getCaptcha');
            }
          });
        }

        if (type === constant.MFA) {
          this.$router.push('/mfa');
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.login-content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.login {
  margin-top: 60px;
}

.login-fast {
  background-color: #fff;
  border-radius: 10px;
  min-width: 420px;
  height: 470px;
  box-shadow: 0 14px 28px #cdc5c5, 0 10px 10px #cdc5c5;
  margin-top: 100px;
}

.login-ss {
  box-shadow: 0 0 0;
}

.more-login {
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.login-fast-item {
  justify-content: center;
  display: flex;
  box-shadow: 0px 14px 28px #9d9d9d;
  margin: 20px 20px;
  padding: 30px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.web {
  background-color: #10496B;
  height: 40px;
  line-height: 40px;
  color: #fff;
  border-radius: 5px;
  width: 80px;
  text-align: center;
  cursor: pointer;
}
</style>
